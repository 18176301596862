const adminCredentials = {
    email: "admin@admin",
    password: "admin",
  };
  
  /**
   * Function to check if given credentials match the stored ones
   */
  export const checkAdminCredentials = (email, password) => {
    return email === adminCredentials.email && password === adminCredentials.password;
  };
  