import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import articlesData from "./articles";

const AdminDashboard = () => {
  const [articles, setArticles] = useState(articlesData);
  const navigate = useNavigate();

  const handleDelete = (id) => {
    const updatedArticles = articles.filter(article => article.id !== id);
    setArticles(updatedArticles);
  };
  
  return (
    <div style={styles.container}>
      <h1>Admin Dashboard</h1>
      <button onClick={() => navigate("/change-credentials")} style={{ marginBottom: "20px" }}>
    Change Credentials
  </button>
      <table style={styles.table}>
        <thead>
          <tr>
            <th>ID</th>
            <th>Title</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {articles.map(article => (
            <tr key={article.id}>
              <td>{article.id}</td>
              <td>{article.title}</td>
              <td>
                <Link to={`/articles/${article.id}`} style={styles.button}>View</Link>
                <Link to={`/admin/edit/${article.id}`} style={styles.button}>Edit</Link>
                <button onClick={() => handleDelete(article.id)} style={styles.deleteButton}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

// Basic Styles
const styles = {
  container: {
    maxWidth: "800px",
    margin: "auto",
    textAlign: "center",
    padding: "20px",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    marginTop: "20px",
  },
  button: {
    margin: "5px",
    padding: "5px 10px",
    textDecoration: "none",
    backgroundColor: "#007bff",
    color: "white",
    borderRadius: "5px",
  },
  deleteButton: {
    margin: "5px",
    padding: "5px 10px",
    backgroundColor: "red",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
};

export default AdminDashboard;
