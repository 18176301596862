import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import articlesData from "./articles";

const EditArticle = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const article = articlesData.find(a => a.id === parseInt(id));

  const [title, setTitle] = useState(article?.title || "");
  const [summary, setSummary] = useState(article?.summary || "");
  const [content, setContent] = useState(article?.content || "");

  if (!article) return <h2>Article not found</h2>;

  const handleSave = (e) => {
    e.preventDefault();
    article.title = title;
    article.summary = summary;
    article.content = content;
    navigate("/admin-dashboard");
  };

  return (
    <div style={styles.container}>
      <h2>Edit Article</h2>
      <form onSubmit={handleSave} style={styles.form}>
        <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} required style={styles.input} />
        <textarea value={summary} onChange={(e) => setSummary(e.target.value)} required style={styles.textarea} />
        <textarea value={content} onChange={(e) => setContent(e.target.value)} required style={styles.textarea} />
        <button type="submit" style={styles.button}>Save Changes</button>
      </form>
    </div>
  );
};

// Basic Styles
const styles = {
  container: {
    maxWidth: "600px",
    margin: "auto",
    textAlign: "center",
    padding: "20px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  input: {
    padding: "10px",
    fontSize: "16px",
    borderRadius: "5px",
    border: "1px solid #ccc",
  },
  textarea: {
    padding: "10px",
    fontSize: "16px",
    borderRadius: "5px",
    border: "1px solid #ccc",
    height: "100px",
  },
  button: {
    padding: "10px",
    fontSize: "16px",
    backgroundColor: "#007bff",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
};

export default EditArticle;
