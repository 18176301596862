import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ArticlePage from "./ArticlePage";
import Homepage from "./homepage";
import AdminLogin from "./AdminLogin";
import AdminDashboard from "./AdminDashboard";
import EditArticle from "./EditArticle";


const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/articles/:id" element={<ArticlePage />} />
        <Route path="/admin-login" element={<AdminLogin />} />
        <Route path="/admin-dashboard" element={<AdminDashboard />} />
        <Route path="/admin/edit/:id" element={<EditArticle />} />

      </Routes>
    </Router>
  );
};



export default App;
