import React, { useState } from "react";
import { Link } from "react-router-dom";
import articles from "./articles";

const articlesPerPage = 10; // Change this to adjust how many articles per page

const Homepage = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(articles.length / articlesPerPage);
  const startIndex = (currentPage - 1) * articlesPerPage;
  const visibleArticles = articles.slice(startIndex, startIndex + articlesPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  // State to track hover for each article card
  const [hoveredIndex, setHoveredIndex] = useState(null);

  return (
    <div style={styles.page}>
      {/* Welcome Banner */}
      <header style={styles.header}>
        <h1>Welcome to Our Blog</h1>
        <p>Discover insightful articles on technology, security, and innovation.</p>
      </header>

      {/* Article List */}
      <div style={styles.articlesContainer}>
        {visibleArticles.map((article, index) => (
          <Link
            to={article.url}
            key={article.id}
            style={styles.cardLink}
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(null)}
          >
            <div
              style={{
                ...styles.articleCard,
                ...(hoveredIndex === index ? styles.articleCardHover : {}),
              }}
            >
              <h2>{article.id}. {article.title}</h2>
              <p>{article.summary}</p>
            </div>
          </Link>
        ))}
      </div>

      {/* Pagination Controls */}
      <div style={styles.pagination}>
        <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
          ◀ Prev
        </button>
        <span> Page {currentPage} of {totalPages} </span>
        <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
          Next ▶
        </button>
      </div>
    </div>
  );
};

// 🎨 Styling
const styles = {
  page: {
    fontFamily: "Arial, sans-serif",
    backgroundColor: "#f9f9f9",
    minHeight: "100vh",
    padding: "20px",
  },
  header: {
    textAlign: "center",
    padding: "40px 20px",
    backgroundColor: "#007bff",
    color: "#fff",
    borderRadius: "10px",
    marginBottom: "30px",
  },
  articlesContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: "20px",
  },
  cardLink: {
    textDecoration: "none",
    width: "90%",
    maxWidth: "400px",
  },
  articleCard: {
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    transition: "transform 0.2s ease, box-shadow 0.2s ease",
    cursor: "pointer",
    display: "block", 
    textDecoration: "none", 
    color: "#333", 
  },
  articleCardHover: {
    transform: "scale(1.05)",
    boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.15)",
  },
  articleGrid: { 
    display: "grid", 
    gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))", 
    gap: "20px", 
    padding: "20px" 
  },
  pagination: { 
    marginTop: "20px", 
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: "20px"
  },
};

export default Homepage;
