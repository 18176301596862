import React from "react";
import { useParams, Link } from "react-router-dom";
import articles from "./articles";

const ArticlePage = () => {
  const { id } = useParams();
  const article = articles.find(a => a.id === parseInt(id));

  if (!article) return <h2>Article not found</h2>;

  // Dynamically import the image
  let imageSrc;
  try {
    imageSrc = require(`./images/${article.id}.jpg`);
  } catch (error) {
    imageSrc = require(`./images/default.jpg`); // Fallback image if not found
  }

  return (
    <div style={styles.container}>
      <img src={imageSrc} alt={article.title} style={styles.image} />
      <h1>{article.title}</h1>
      <div dangerouslySetInnerHTML={{ __html: article.content }} />

      {/* Home Button */}
      <Link to="/" style={styles.homeButton}>🏠 Back to Home</Link>
    </div>
  );
};

// 🎨 Styling
const styles = {
  container: {
    maxWidth: "800px",
    margin: "auto",
    textAlign: "center",
    padding: "20px",
  },
  image: {
    width: "100%",
    height: "auto",
    maxHeight: "500px",
    objectFit: "contain",
    borderRadius: "8px",
    marginBottom: "20px",
  },
  homeButton: {
    display: "inline-block",
    padding: "10px 20px",
    fontSize: "16px",
    color: "#fff",
    backgroundColor: "#007bff",
    borderRadius: "5px",
    textDecoration: "none",
    marginTop: "20px",
  }
};

export default ArticlePage;
