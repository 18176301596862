import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { checkAdminCredentials } from "./creds";

const AdminLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState(""); // Message state for success/failure
  const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();
    if (checkAdminCredentials(email, password)) {
      setMessage("Login successful! Redirecting...");
      setTimeout(() => navigate("/admin-dashboard"), 1000); // Redirect after 1 sec
    } else {
      setMessage("Invalid credentials!"); 
    }    
  };

  return (
    <div style={styles.container}>
      <h2>Admin Login</h2>
      <form onSubmit={handleLogin} style={styles.form}>
        <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required style={styles.input} />
        <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required style={styles.input} />
        <button type="submit" style={styles.button}>Login</button>
      </form>
      {message && <p style={{ color: message.includes("successful") ? "green" : "red" }}>{message}</p>}
    </div>
  );
}; 

// Basic CSS styles
const styles = {
    container: {
      maxWidth: "400px",
      margin: "auto",
      textAlign: "center",
      padding: "20px",
      border: "1px solid #ddd",
      borderRadius: "8px",
      boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
      marginTop: "50px",
    },
    form: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    input: {
      padding: "10px",
      fontSize: "16px",
      borderRadius: "5px",
      border: "1px solid #ccc",
    },
    button: {
      padding: "10px",
      fontSize: "16px",
      backgroundColor: "#007bff",
      color: "#fff",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
    },
};

export default AdminLogin;
